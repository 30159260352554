import { URL_COMPANY } from '@/constants/global';
import {
  isExclusiveMandate,
  ListingStatus,
  MandateType
} from '@proprioo/hokkaido';
import { TagColorsTypes, TagProps } from '@proprioo/salatim';

import { ListingHit } from './interfaces';

export const buildTags = (
  nbPieces: number,
  surface: number,
  nbBedrooms: number
) => {
  const tags: TagProps[] = [
    {
      labels: [nbPieces ? `${nbPieces}P` : 'Studio'],
      color: TagColorsTypes.TERRACOTA
    }
  ];

  if (surface) {
    tags.push({
      labels: [`${Math.round(surface)}m²`],
      color: TagColorsTypes.TERRACOTA
    });
  }

  if (nbBedrooms) {
    tags.push({
      labels: [`${nbBedrooms}CH`],
      color: TagColorsTypes.TERRACOTA
    });
  }

  return tags;
};

export const convertToListingCardProps = ({
  uri,
  codePostal,
  prix,
  ville,
  titre,
  typeBien,
  pictures,
  nbPieces,
  surface,
  nbBedrooms,
  virtualVisit,
  mandate,
  status,
  firstPublication,
  finalSaleDate,
  finalSalePrice,
  groundFloor,
  hasCellarOrAnnex,
  hasLift,
  hasParking,
  hasPool,
  hasOutdoorSpace,
  withoutRenovationWork,
  objectID
}: ListingHit) => {
  const isSold = status === ListingStatus.SOLD;
  const isExclusive = Boolean(
    mandate?.mandateType === MandateType.FULL_EXCLUSIVITY
  );

  return {
    tags: buildTags(nbPieces, surface, nbBedrooms),
    firstPublication,
    finalSaleDate,
    groundFloor,
    hasCellarOrAnnex,
    hasLift,
    hasParking,
    hasPool,
    hasOutdoorSpace,
    withoutRenovationWork,
    isSold,
    isExclusive: isSold
      ? isExclusive
      : Boolean(
          mandate &&
            isExclusiveMandate(
              mandate.mandateType,
              new Date(mandate.exclusivityEndDate * 1000).toString()
            )
        ),
    title: titre,
    type: typeBien,
    price: isSold ? finalSalePrice : prix,
    city: ville,
    postalCode: codePostal,
    pictures,
    nodalLink: virtualVisit,
    uri: uri.replace(URL_COMPANY, ''),
    id: objectID
  };
};

export const haveHits = (hits: ListingHit[]) => hits.length > 0;
