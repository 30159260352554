import { useRouter } from 'next/router';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import NodalIcon from '@/assets/icons/nodalview.svg';
import { extractOtherCriterias } from '@/components/search/filters/Filters.utils';
import ListingCriterias from '@/components/search/listingCriterias/ListingCriterias';
import { UrlProps, URLS } from '@/constants/global';
import { keepOnlyQueryStringFromURL } from '@/utils/algolia';
import { isOlderThanOneMonth } from '@proprioo/hokkaido';
import { Tag, TagColorsTypes } from '@proprioo/salatim';

import CustomLink from '../customLink/CustomLink';
import ListingCardDetails from '../listingCardDetail/ListingCardDetail';
import { ListingCardHitProps } from './interfaces';
import {
  Card,
  CriteriasLayout,
  Nodalview,
  NodalWrapper,
  TagsLayout,
  TagWrapper
} from './ListingCard.styles';
import { salesDurationTagValue, shouldDisplaySaleTag } from './utils';

export const ListingCard: FC<ListingCardHitProps> = props => {
  const { asPath } = useRouter();
  const { t } = useTranslation();

  const {
    firstPublication,
    finalSaleDate,
    nodalLink,
    hasCellarOrAnnex,
    hasLift,
    hasOutdoorSpace,
    hasParking,
    hasPool,
    withoutRenovationWork,
    groundFloor,
    isSold = false,
    isExclusive = false,
    title,
    uri
  } = props;

  const query = keepOnlyQueryStringFromURL(asPath);
  const queryString = query ? `${query}` : '';

  const today = new Date();
  const link: UrlProps = {
    ...URLS.LISTING,
    as: `${uri}${queryString}`,
    label: title
  };

  const otherCriterias = extractOtherCriterias({
    hasCellarOrAnnex,
    hasLift,
    hasOutdoorSpace,
    hasParking,
    hasPool,
    withoutRenovationWork,
    groundFloor
  });

  const isNew =
    firstPublication &&
    !isSold &&
    !isOlderThanOneMonth(today, new Date(firstPublication));

  const isSoldInFewDays = shouldDisplaySaleTag(
    isSold,
    finalSaleDate,
    firstPublication
  );

  const hasTags = Boolean(
    [isNew, isExclusive, isSoldInFewDays].filter(Boolean).length
  );

  return (
    <Card data-test="listing-card" disableHover={!uri}>
      <TagWrapper data-test="listing-tags">
        <div>
          {hasTags && (
            <TagsLayout>
              {isNew && (
                <Tag
                  color={TagColorsTypes.TERRACOTA}
                  dataTest="tag-new"
                  labels={[t('new')]}
                />
              )}
              {isExclusive && (
                <Tag
                  color={TagColorsTypes.GREEN_LIGHT}
                  dataTest="exclusive-tag"
                  labels={[t('exclusive')]}
                />
              )}
              {isSoldInFewDays && (
                <Tag
                  color={TagColorsTypes.TERRACOTA}
                  dataTest="sale-duration-tag"
                  labels={[
                    t('sellingDuration', {
                      date: salesDurationTagValue(
                        finalSaleDate ? new Date(finalSaleDate) : today,
                        firstPublication ? new Date(firstPublication) : today
                      )
                    })
                  ]}
                />
              )}
            </TagsLayout>
          )}
          {Boolean(otherCriterias.length) && (
            <CriteriasLayout>
              <ListingCriterias criterias={otherCriterias} />
            </CriteriasLayout>
          )}
        </div>
        <NodalWrapper>
          {nodalLink && !isSold && (
            <Nodalview
              aria-label={t('virtualVisit')}
              data-test="virtual-visit-link"
              href={nodalLink}
              rel="noreferrer noopener"
              target="_blank"
            >
              <NodalIcon />
            </Nodalview>
          )}
        </NodalWrapper>
      </TagWrapper>
      {!isSold && Boolean(uri) ? (
        <CustomLink data-test="link-visit" link={link}>
          <ListingCardDetails {...props} />
        </CustomLink>
      ) : (
        <ListingCardDetails {...props} />
      )}
    </Card>
  );
};

export default ListingCard;
