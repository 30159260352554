import { X_REQUESTED_WITH } from '@/constants/constants';
import { GRAPH_TOKEN } from '@/constants/global';
import { HttpMethod } from '@proprioo/hokkaido';

import { ArbitraryObject } from './test-utils';

export enum GraphOperationName {
  AGENT = 'AgentById',
  GET_DOCUMENT = 'GetDocument',
  LISTING = 'Listing',
  LISTING_UUID = 'ListingByUUID',
  SOLD_LISTING = 'PublishedList',
  USER = 'User',
  USER_EDIT = 'UserEdit'
}

const fetchHandler = async <T>(response: Response): Promise<T> => {
  if (response.ok) {
    return response
      .json()
      .then(json => Promise.resolve(json))
      .catch(() => Promise.resolve({ response }));
  } else {
    return response
      .json()
      .catch(() => {
        throw response.statusText;
      })
      .then(json => {
        if (Array.isArray(json.errors)) {
          throw json.errors;
        } else {
          throw json;
        }
      });
  }
};

export const fetcher = async <T>(
  url: string,
  options?: RequestInit,
  binary?: boolean
): Promise<T> => {
  const headers = new Headers(options?.headers);

  if (!headers.has('Content-Type') && !binary) {
    headers.append('Content-Type', 'application/json');
  }

  if (!headers.has('Accept')) {
    headers.append('Accept', 'application/json');
  }

  if (!headers.has('X-Requested-With')) {
    headers.append('X-Requested-With', X_REQUESTED_WITH);
  }

  return await fetch(url, {
    ...options,
    headers
  }).then(response => fetchHandler<T>(response));
};

const setGraphRequest = (isSSGQuery: boolean, customToken: string) => {
  const token = isSSGQuery
    ? `${process.env.SSG_GRAPH_TOKEN}`
    : customToken || `${GRAPH_TOKEN}`;
  const headers = new Headers();

  headers.append('Content-Type', 'application/json');
  headers.append('X-Requested-With', X_REQUESTED_WITH);
  headers.append('Authorization', token);

  return headers;
};

const fetchGraphResponse = (
  response: Response,
  operationName: GraphOperationName
) => {
  const contentType = response.headers.get('Content-Type');
  if (contentType?.includes('application/json')) {
    return response.json().then(json => json);
  } else {
    throw new Error(
      `No application/json data in graph response for operation ${operationName}`
    );
  }
};

export const graphRequest = async <T>(
  operationName: GraphOperationName,
  query: string,
  variables?: ArbitraryObject,
  isSSGQuery = false,
  token = ''
): Promise<{ data: T; errors?: [] }> => {
  const headers = setGraphRequest(isSSGQuery, token);
  const url = isSSGQuery
    ? `${process.env.SSG_GRAPH_URL}`
    : `${process.env.GRAPH_URL}`;

  return await fetch(url, {
    method: HttpMethod.POST,
    body: JSON.stringify({
      operationName,
      query,
      variables
    }),
    headers
  })
    .then(response => fetchGraphResponse(response, operationName))
    .catch(error => error);
};
