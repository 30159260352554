import styled, { css } from 'styled-components';

import { convertPxToRem } from '@proprioo/salatim';

import { LayoutArrow } from '../carousel/Carousel.styles';

export const Card = styled.div<{ disableHover: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  isolation: isolate;

  ${({ disableHover }) =>
    !disableHover &&
    css`
      &:hover {
        ${LayoutArrow} {
          visibility: visible;
        }
      }
    `}
`;

export const TagWrapper = styled.div`
  position: absolute;
  display: flex;
  left: ${convertPxToRem(32)};
  top: ${convertPxToRem(24)};
  right: ${convertPxToRem(32)};
  z-index: 3;
  pointer-events: none;
`;

export const NodalWrapper = styled.div`
  margin-left: auto;
`;

export const Nodalview = styled.a`
  display: flex;
  height: ${convertPxToRem(44)};
  width: ${convertPxToRem(44)};
  align-items: center;
  justify-content: center;
  color: white;
  transition: 0.2s transform ease-out;

  :hover {
    transform: scale(0.92);
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const CriteriasLayout = styled.div``;

export const TagsLayout = styled.div`
  display: flex;

  + ${CriteriasLayout} {
    margin-top: ${convertPxToRem(8)};
  }
`;
