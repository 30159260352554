import Image from 'next/image';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  formatPrice,
  PLACEHOLDER_SOON_CASAVO,
  POSTAL_CODES
} from '@proprioo/hokkaido';
import { Tag, TagColorsTypes } from '@proprioo/salatim';

import Carousel from '../carousel/Carousel';
import { ListingCardHitProps } from '../listingCard/interfaces';
import { convertToPicturesProps, separateTags } from '../listingCard/utils';
import {
  CardBody,
  CardInfo,
  CardLabel,
  CardMedias,
  CardPicture,
  CardTag,
  CardTextHighlight,
  CardTitle,
  CardWrapper
} from './ListingCardDetail.styles';
import { formatDistrict } from './utils';

const ListingCardDetails: FC<ListingCardHitProps> = ({
  tags,
  title,
  type,
  price,
  pictures = [],
  isSold = false,
  uri,
  city,
  postalCode
}) => {
  const { t } = useTranslation();
  const { visibles, others } = separateTags(tags);
  const showPointer = !isSold && Boolean(uri);

  const [firstPicture] = pictures;

  return (
    <CardWrapper data-test="card-wrapper">
      <CardMedias showPointer={showPointer}>
        {pictures.length ? (
          <Carousel
            pictures={convertToPicturesProps(
              isSold ? [firstPicture] : pictures
            )}
          />
        ) : (
          <CardPicture data-test="card-placeholder">
            <Image
              alt=""
              fill={true}
              loading="lazy"
              quality={85}
              src={PLACEHOLDER_SOON_CASAVO}
            />
          </CardPicture>
        )}
      </CardMedias>
      <CardBody showPointer={showPointer}>
        <CardTag data-test="card-tag">
          <Tag
            labels={[formatDistrict(POSTAL_CODES[postalCode] || city)]}
            color={TagColorsTypes.TERRACOTA}
          />
        </CardTag>
        <CardTitle>
          <CardLabel data-test="card-title" title={title}>
            {t(type)}
          </CardLabel>
          {visibles.map(({ labels }, index) => (
            <CardInfo key={index} data-test="card-visible-tags">
              {labels}
            </CardInfo>
          ))}
          {others &&
            others.labels.map((label, index) => (
              <CardInfo key={index} data-test="card-other-tags">
                {label}
              </CardInfo>
            ))}
        </CardTitle>
        {price && (
          <CardTextHighlight data-test="card-text-highlight">
            {formatPrice(price)}
          </CardTextHighlight>
        )}
      </CardBody>
    </CardWrapper>
  );
};

export default ListingCardDetails;
