import { differenceInDays } from 'date-fns/differenceInDays';
import { formatDuration } from 'date-fns/formatDuration';
import { intervalToDuration } from 'date-fns/intervalToDuration';
import { fr as french } from 'date-fns/locale';
import { startOfDay } from 'date-fns/startOfDay';

import { Picture } from '@/components/listing/listing/Listing.interfaces';
import { IMAGES_URL } from '@/constants/global';
import { computePictureFromAssets, ListingPicture } from '@proprioo/hokkaido';
import { TagColorsTypes, TagProps } from '@proprioo/salatim';

import { IMAGE_RATIO } from '../carousel/Carousel.styles';

export const mergeTags = (tags: TagProps[]) => ({
  labels: tags.reduce(
    (acc: string[], val: TagProps) => acc.concat(val.labels),
    []
  ),
  color: (tags[0] && tags[0].color) || TagColorsTypes.TERRACOTA
});

export const getLabelsLength = (tag: TagProps) => tag.labels.join('').length;

export const buildOthersTag = (tags: TagProps[]) => {
  const othersButton: TagProps = {
    labels: ['...'],
    color: TagColorsTypes.TERRACOTA
  };

  if (tags.length) {
    tags.unshift(othersButton);
    return mergeTags(tags);
  }

  return;
};

export const separateTags = (tags: TagProps[]) => {
  const visibles: TagProps[] = [];
  const max = 30;
  let size = 0;
  let index = 0;

  while (index < tags.length) {
    const tag = tags[index];
    const tagSize = getLabelsLength(tag);
    if (tagSize + size <= max) {
      visibles.push(tag);
      size += tagSize;
    } else {
      break;
    }
    index++;
  }

  return { visibles, others: buildOthersTag(tags.slice(index)) };
};

export const shouldDisplaySaleTag = (
  isSold: boolean,
  finalSaleDate?: string,
  firstPublication?: string
): boolean =>
  Boolean(
    isSold &&
      finalSaleDate &&
      firstPublication &&
      differenceInDays(new Date(finalSaleDate), new Date(firstPublication)) >=
        0 &&
      differenceInDays(new Date(finalSaleDate), new Date(firstPublication)) <=
        31 &&
      salesDurationTagValue(new Date(finalSaleDate), new Date(firstPublication))
  );

export const salesDurationTagValue = (
  finalSaleDate: Date,
  firstPublication: Date
) =>
  formatDuration(
    intervalToDuration({
      start: startOfDay(firstPublication),
      end: startOfDay(finalSaleDate)
    }),
    { format: ['years', 'months', 'weeks', 'days'], locale: french }
  );

export const convertToPicturesProps = (pictures: ListingPicture[]): Picture[] =>
  pictures
    .map(pic => ({
      url: computePictureFromAssets(pic, 600, IMAGE_RATIO, IMAGES_URL)
    }))
    .slice(0, 5);
