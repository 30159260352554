import styled, { css } from 'styled-components';

import { Image } from '@/utils/styles';
import {
  BagossFont,
  breakpoints,
  colors,
  convertPxToRem,
  fonts,
  paragraphs,
  radius,
  shadows
} from '@proprioo/salatim';

import { IMAGE_RATIO } from '../carousel/Carousel.styles';

type CardProps = {
  showPointer?: boolean;
  backgroundColor?: string;
  isBigger?: boolean;
};

export const CardBody = styled.div<CardProps>`
  background-color: white;
  padding: ${convertPxToRem(24)} ${convertPxToRem(16)};
  z-index: 2;
  position: relative;
  box-shadow: ${shadows.level2};
  color: ${colors.dark.base};
  min-height: ${convertPxToRem(110)};

  @media (min-width: ${breakpoints.normal}px) {
    padding: ${convertPxToRem(32)};
  }

  ${({ isBigger }) =>
    isBigger &&
    css`
      min-height: ${convertPxToRem(140)};

      @media (min-width: ${breakpoints.normal}px) {
        min-height: ${convertPxToRem(192)};
      }
    `};

  ${({ showPointer }) =>
    showPointer &&
    css`
      cursor: pointer;
    `};

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `};
`;

export const CardTag = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  white-space: nowrap;
`;

export const CardTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  h6 {
    margin: 0;
    padding: 0;
    font-size: ${fonts.base};
  }
`;

export const CardLabel = styled.p`
  ${paragraphs.body1};
  font-weight: bold;
`;

export const CardInfo = styled.p`
  ${paragraphs.body1};
  text-transform: lowercase;
  display: flex;
  align-items: center;

  ::before {
    content: '';
    width: ${convertPxToRem(3)};
    height: ${convertPxToRem(3)};
    border-radius: ${radius.rounded};
    background: ${colors.red.base10};
    margin: 0 ${convertPxToRem(4)};
    display: inline-block;
  }
`;

export const CardText = styled.p`
  ${paragraphs.body1};
`;

export const CardTextHighlight = styled.p`
  ${BagossFont};
  font-weight: bold;
  font-size: ${fonts.big};
  color: ${colors.terracota.base};
  text-transform: lowercase;
  text-align: center;
  margin: ${convertPxToRem(16)} 0 0;
  padding: 0;
`;

export const CardMedias = styled.div<Pick<CardProps, 'showPointer'>>`
  position: relative;
  z-index: 1;
  padding-bottom: ${(IMAGE_RATIO * 100).toFixed(1)}%;
  background: ${colors.grey.base10};

  ${({ showPointer }) =>
    showPointer &&
    css`
      cursor: pointer;
    `}
`;

export const CardPicture = styled.picture`
  ${Image};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    object-fit: cover;
    transition: 0.6s transform ease-out;
  }
`;

export const CardWrapper = styled.div`
  position: relative;

  @media (min-width: ${breakpoints.base}px) {
    width: auto;
  }
`;
